<template>
  <FormContainer @submitForm="submit">
    <FeedbackAlert v-if="feedback === false">Errore nel salvataggio dei dati! Riprovare</FeedbackAlert>

    <div class="row">
      <FormInputTextWidget
        class="col-md-6"
        label="Nome"
        v-model="internalValue.name"
        :errorMsg="errorHash.name"
        @input="removeFieldError('name')"
      />

      <FormInputTextWidget
        class="col-md-6"
        label="Url immagine"
        not-required
        v-model="internalValue.imageUrl"
        :errorMsg="errorHash.imageUrl"
        @input="removeFieldError('imageUrl')"
      />
    </div>

    <div class="row">
      <FormInputTextareaWidget
        class="col-12"
        label="Descrizione"
        not-required
        v-model="internalValue.description"
        :errorMsg="errorHash.description"
        @input="removeFieldError('description')"
      />
    </div>

    <div class="row">
      <div class="col-12 mt-4 text-right">
        <button class="btn btn-primary px-5 font-weight-bold">Salva</button>
      </div>
    </div>
  </FormContainer>
</template>

<script>

/* eslint-disable */

import validateMixin from '@/libs/Form/mixins/validate.js';

import FormContainer from '@/views/components/Form/FormContainer.vue';
import FormInputTextWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextWidget.vue';
import FormInputTextareaWidget from '@/libs/Form/components/Inputs/Widgets/FormInputTextareaWidget.vue';
import FeedbackAlert from '@/libs/Feedback/components/Alert.vue';

export default {
  mixins: [validateMixin],
  components: { FormContainer, FormInputTextWidget, FormInputTextareaWidget, FeedbackAlert },
  props: {
    value: {
      type: Object,
      default: () => ({
        name: null,
        description: null,
        imageUrl: null,
      }),
    },
    edit: {
      type: Boolean,
      default: false,
    },
    feedback: {
      type: Boolean,
      default: null,
    },
  },
  data () {
    return {
      iValue: { ...this.value },
    };
  },
  computed: {
    internalValue: {
      get () {
        return this.iValue;
      },
      set (value) {
        this.iValue = value;
      },
    },
  },
  watch: {
    value: {
      handler (newValue) {
        this.iValue = { ...newValue };
      },
      immediate: true,
    },
  },
  methods: {
    removeFieldError (field) {
      this.removeFeedback();
      this.removeError(field);
    },
    removeFeedback () {
      this.$emit('update:feedback', null);
    },
    isValid () {
      this.clearErrors();
      this.removeFeedback();

      if (!this.iValue.name) {
        this.addError('Inserisci il nome', 'name');
      }

      return !this.hasErrors;
    },
    submit () {
      if (!this.isValid()) {
        return;
      }

/*
      if (!this.uploadsFinishedAndReadyToSubmitInInstance('cover')) {
        this.addError('cover', 'Caricamento immagine in corso');

        return;
      }
*/

      this.$emit('submitForm', { ...this.iValue });
    },
  },
};

</script>
